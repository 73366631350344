<script>
import CompanyInfoCard from "./info-card/CompanyInfoCard";
import {bus} from "@/router/bus";
import LetterInfoCard from "./LetterInfoCard";
import ModalNotification from "../common/ModalNotification";
import AnalysisRateForm from "./forms/AnalysisRateForm";
import ManagerList from "./managers-contact/ManagerList";
import AppLoader from "@/components/ui/AppLoader.vue";
import AppCheckbox from "@/components/ui/AppCheckbox.vue";

export default {
  name: 'Profile',
  components: {
    CompanyInfoCard,
    LetterInfoCard,
    ModalNotification,
    AnalysisRateForm,
    ManagerList,
    AppLoader,
    AppCheckbox
  },
  
  data() {
    return {
      tab: null,
      items: [
        'Учётная запись',
        'Уведомления',
        'Рассылка',
        'Анализ'
      ],
      showGuide: false,
      showFileForm: false,
      showAcceptNotify: false,
      showDocRequestForm: false,
      showDeclineMailNotify: false,
      
      best_rate_param: [],
      
      activeCompanyKey: 0,
      
      profileData: [],
      sortedManagers: [],
      showloader:  null,
      showAddButton: false,
      notify_events: [],
      client_data: [],
  
      date_from: '',
      date_to: '',
    }
  },
  
  computed: {
    isGuided() {
      if (this.$_getsetting('is_guid_profile')) {
        return true
      } else { return false }
    },
    
    isActiveParams() {
      return this.best_rate_param.some(param => param.is_active === 1)
      // return true
    },

    clientId(){
      return this.$_getsetting('client_id')
    }
  },
  
  mounted() {
    this.clientId != -1 ? this.getUser() : null

    bus.$on('logout_user', () => {
      this.logOut()
    })
  },
  
  methods: {
    getUser() {
      this.showloader = true
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.showloader = false
          this.profileData = JSON.parse(data.message)
          this.notify_events = this.profileData.notify_events.filter(item => item.field_description != null)
          this.getThreeBestRateParams()
          this.storeRequisite()
          this.sortManager()
        }
      });
    },
    
    sortManager() {
      this.sortedManagers = this.profileData.linked_users.users
        .filter(item => item != null)
        .sort(function (a, b) {
        return a.sort - b.sort;
      });
    },

    storeRequisite() {
      if (this.profileData.payers_data[0].accounts_data[0] == null) {
        this.$_setsetting('req_checked', false)
      } else {
        this.$_setsetting('req_checked', true)
      }
    },
    
    checkRating() {
      let lowRate = false
      for (let i = 0; i < this.profileData.payers_data.length; i++) {
        if (this.profileData.payers_data[i].company_rate < 5) {
          lowRate = true
        }
      }
      this.$_setsetting('low_company_rate', lowRate)
    },
    
    setActiveThreeBestRateParams(id) {
      this.$postapi(this.$address + this.$getters.setActiveThreeBestRateParams.uri, {
        method: this.$getters.setActiveThreeBestRateParams.name,
        id: id,
        is_active: 0,
      }).then((data) => {
        if (data.error == 0) {
          this.showDeclineMailNotify = true
          this.getThreeBestRateParams()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },
    
    displayGuid() {
      if (!this.isGuided) {
        this.$_setsetting('is_guid_profile', true)
        this.showGuide = true
      }
      else {
        this.showGuide = false
      }
    },
    
    gotoCabinet() {
      this.$router.push("/Cabinet")
    },
    
    gotoAddCompanyInfoPage() {
      this.$router.push("/AddCompanyData")
    },
    
    logOut() {
      this.$_removesetting('token')
      this.$_removesetting('user_id')
      this.$_removesetting('login')
      this.$_removesetting('email')
      this.$_removesetting('is_auth')
      this.$_removesetting('client_id')
      this.$_removesetting('fio')
      this.$_removesetting('clnt_name')
      this.$_removesetting('manager_email')
      this.$_removesetting('is_show_bm_messages')
      this.$_removesetting('manager_name')
      this.$_removesetting('manager_phone')
      this.$_removesetting('is_block')
      this.$_removesetting('is_added_block')
      this.$_removesetting('is_view_orders_block')
      this.$_removesetting('is_view_fin_block')

      for (let key in localStorage) {
        if (key.startsWith('request_data')) {
          this.$_removesetting(key);
        }
      }
      location.reload()
      this.$router.push({ path: '/'})
    },
    
    changeNotify(data){
      for(let i = 0; i < this.notify_events.length; i++){
        if (this.notify_events[i].id == data.id && this.notify_events[i].field_name == data.field_name ){
          if (data.is_active == true)
            this.notify_events[i].is_active = 1;
          else
            this.notify_events[i].is_active = 0;
        }
      }
    },
    
    selectAllNotify(active_state) {
      for (let i = 0; i < this.notify_events.length; i++) {
        this.notify_events[i].is_active = active_state;
      }
    },
    
    saveDataNotify(){
      for (let i = 0; i < this.notify_events.length; i++) {
        this.$postapi(this.$address + this.$setters.setNotifyStatus.uri, {
          method: this.$setters.setNotifyStatus.name,
          id: this.notify_events[i].id,
          value: +this.notify_events[i].is_active
        }).then(data => {
          if (data.error != 0) {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
      }
      this.showAcceptNotify = true
    },
    
    getThreeBestRateParams() {
      this.$postapi(this.$address + this.$getters.getThreeBestRateParams.uri, {
        method: this.$getters.getThreeBestRateParams.name,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0){
          this.best_rate_param = JSON.parse(data.message);
        }
      });
    },
  }
}
</script>

<template>
  <div class="profile">
    <h1 class="profile__title">
      Профиль
      
      <v-btn
        class="profile__btn"
        text
        x-large
        color="var(--main-bg-color)"
        @click="gotoCabinet"
      >
        В личный кабинет
      </v-btn>
      
      <v-tooltip z-index="10000000000" color="black" top content-class="profile__tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="profile__btn"
            v-bind="attrs"
            v-on="on"
            text
            x-large
            color="var(--main-bg-color)"
            @click="logOut"
          >
            <v-icon>
              mdi-logout
            </v-icon>
          </v-btn>
        </template>
        
        <span>Выйти</span>
      </v-tooltip>
    </h1>
    
    <v-btn
      class="profile__btn-mobile"
      text
      plain
      x-small
      color="black"
      @click="gotoCabinet"
    >
      В личный кабинет
      <v-icon class="mt-1">
        mdi-chevron-right
      </v-icon>
    </v-btn>
    
    <v-btn
      class="profile__btn-mobile"
      text
      x-small
      plain
      color="black"
      @click="gotoCabinet"
    >
      Выход
      <v-icon class="mt-1 ml-1">
        mdi-logout
      </v-icon>
    </v-btn>
    
    <v-tabs
      class="profile__tabs"
      v-model="tab"
      background-color="transparent"
      color="basil"
    >
      <v-tab
        v-for="item in items"
        :key="item"
        :value="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <p class="client-msg" v-if="clientId == -1 && !showloader">Вы - менеджер Vedexx</p>
    <v-tabs-items v-model="tab" v-else-if="!showloader">
      <v-tab-item>
        <div
          class="profile__company-card"
          v-if="profileData.length != 0 && profileData.client_data[0] !=null"
        >
  
          <div class="profile__company-card-short">
            <v-card elevation="3" style="border-radius: 8px">
              <v-card
                v-for="(payers_data, index) in profileData.payers_data"
                :key="index"
                elevation="0"
                :class="[
                  'profile_card', 'profile__card-info',
                  activeCompanyKey == index ? 'profile_card-active' : ''
                  ]"
                @click="activeCompanyKey = index"
              >
                <v-card-text class="row justify-space-between">
                  <div class="profile__info-block">
                    <h1 class="profile__company-card-short__info-ttl">
                      {{ payers_data.name}}
                      <v-badge
                        v-if="payers_data.company_rate < 5"
                        color="var(--error-dark)"
                        content="!"
                        class="mb-1 ml-1"
                      >
                      </v-badge>
                      <!--                        {{payers_data.company_rate}}/10-->
                    </h1>
                    <Transition name="bounce">
                      <div class="profile__company-card-short__info" v-if="activeCompanyKey == index">
                        <h5>
                          {{profileData.email}}
                        </h5>
                        
                        <h5>
                          {{profileData.login}}
                        </h5>
                      </div>
                    </Transition>
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
            
            <v-btn
              v-if="profileData.length != 0 && profileData.payers_data[0] !=null"
              class="profile__add-button mt-4"
              color="var(--bg-dark)"
              elevation="1"
              icon
              @click="gotoAddCompanyInfoPage()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            
            <ManagerList v-if="profileData.linked_users" :managers="sortedManagers"/>
          </div>

          <CompanyInfoCard
            class="profile__company-card__expand"
            :profileData="profileData"
            :payersData="profileData.payers_data[activeCompanyKey]"
            :activeCompanyKey="activeCompanyKey"
            @addFile="showFileForm = true"
            @showDocRequestForm="showDocRequestForm = true"
          />
        </div>
        
        <v-btn
          v-if="!profileData.payers_data"
          :class="[
            'profile__add-button',
            [ showGuide === true ? 'profile__btn_guid' : '']
          ]"
          text
          color="var(--main-bg-color)"
          elevation="2"
          large
          @click="gotoAddCompanyInfoPage()"
        >
          Добавить информацию
        </v-btn>
      </v-tab-item>
      
      <v-tab-item>
        <v-dialog
          v-model="showAcceptNotify"
          persistent
          width="400"
          hide-overlay
        >
          <ModalNotification
            title="Изменения сохранены!"
            @close="showAcceptNotify = false"
          />
        </v-dialog>
        <div class="profile__notify_list_btn">
          <v-btn
            class="select-all-button"
            depressed
            color="var(--main-bg-color)"
            dark
            outlined
            rounded
            @click="selectAllNotify(1)"
          > Выделить всё
          </v-btn>
          
          <v-btn
            class="select-all-button"
            depressed
            color="var(--main-bg-color)"
            dark
            outlined
            rounded
            @click="selectAllNotify(0)"
          > Очистить
          </v-btn>
          
          <v-btn
            class="save-settings-button"
            depressed
            color="var(--main-bg-color)"
            dark
            outlined
            rounded
            @click="saveDataNotify"
          > Сохранить изменения
          </v-btn>
        </div>
        
        <div
          class="profile__notify_list"
        >
          <div
            v-for="item in notify_events"
            :key="item.id"
          >
            <AppCheckbox 
              class="profile__notify_list-item"
              v-if="item.field_description != null"
              v-model="item.is_active"
              :label="item.field_description"
              :value="item.is_active"
              @change="changeNotify(item)"
            />
          </div>
        </div>
      </v-tab-item>
      
      <v-tab-item>
        <v-dialog
          v-model="showDeclineMailNotify"
          persistent
          width="400"
          hide-overlay
        >
          <ModalNotification
            title="Подписка отменена"
            @close="showDeclineMailNotify = false"
          />
        </v-dialog>
        
        <div class="profile__company-card-short__info-ttl ml-2 my-3">Подписка на направления</div>
        <v-alert
          text
          dense
          class="ml-2 profile__company-card-short__info-alert-item"
          color="var(--main-bg-color)"
          icon="mdi-email-newsletter"
          border="left"
          width="50%"
        >
          Получайте свежие обновления по ставкам выбранных направлений прямо на электронную почту
        </v-alert>
        
        <div v-if="best_rate_param != null && best_rate_param.length > 0">
          <div
            class="profile__company-card-short__info-ttl ml-2 my-3"
          >Активные подписки</div>
          <div v-if="isActiveParams == false">
            У вас отсутствуют активные подписки
          </div>
          <div
            v-else
            v-for="(offer, index) in best_rate_param"
            :key="index"
          >
            <LetterInfoCard
              v-if="offer.is_active == 1"
              class="profile__letter-card"
              :offer="offer"
              @declineMail="setActiveThreeBestRateParams($event)"
            />
          </div>
        </div>
      </v-tab-item>
      
      <v-tab-item>
        <AnalysisRateForm/>
      </v-tab-item>
    </v-tabs-items>
    <AppLoader class="app-loader" :size="80" v-else/>
  </div>
</template>

<style scoped lang="scss">
.app-loader{
  margin: 0 auto;
  display: block;
}
.profile {
  
  &__tooltip {
    @include font-body-bold-3;

    color: #FFF;
    line-height: 22px; /* 200% */
    letter-spacing: 0.238px;
    padding-top: 10px;
    height: 45px;
  }
  
  &__company-card {
    display: flex;
    max-width: 100%;
    margin-top: 20px;
    
    &-short {
      width: 27%;
      border-radius: 8px;
      margin: 0 15px 0 5px;
      
      &__info-ttl {
        @include font-body-1;

        font-weight: 600;
        margin: 0 0 0;
        font-size: 22px;
        color: #191919;
      }
      
      &__info {
        @include font-subtitle-regular;

        line-height: 24px;
        margin: 5px 0;
        color: #191919;

        &-alert-item{
          @include font-subtitle-regular;
        }
      }
    }
    
    &__expand {
      width: 55%;
    }
  }
  
  &__letter-card {
    margin: 10px;
    width: 50%;
    
    &:first-child {
      margin-top: 15px;
    }
  }
  
  &__close-guid-btn {
    text-transform: none;
    position: fixed;
    z-index: 2000000000000000;
    bottom: 15%;
    width: 200px;
    left: calc(50% - 100px);
  }

  &__title {
    @include font-headline-1;
 
    margin: 20px 0;

    @media (max-width: 620px) {
      font-size: 22px;
      display: block;
    }
  }

  &__btn {
    @include font-body-bold-2;
    font-size: 18px;
    // font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-transform: capitalize;

    @media (max-width: 620px) {
      display: none;
    }
  }

  &__btn-mobile {
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: block;
    margin: 10px 0;

    @media (min-width: 621px) {
      display: none;
    }
  }

  &__btn_guid {
    font-size: 18px;
    background: white;
    margin-right: 10px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    z-index: 9999999999999999999999;
  }

  &__tabs {
    @include font-description-0;

    &__tab {
      display: flex;
      margin: 30px 0;

      @media (max-width: 700px) {
        flex-direction: column;
      }
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  &__add-button {
    display: block;
    margin: 10px auto;
    padding: 5px;
    font-weight: 800;
    font-size: 14px;
    text-transform: none;
    //border: 1px dashed rgba(67, 98, 114, 0.5);
  }
  
  &__notify_list {
    margin: 1px 1px 0 10px;
    font-size: 24px;
    display: grid;
    width: 70%;
    grid-template-columns: repeat(3, 1fr);

    gap: 30px 0;
    margin-top: 30px;

    @media (max-width: $tablet-width){
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: $mobile-width){
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__notify_list_btn{
    @include font-subtitle-medium;
    margin: 15px 10px 17px 10px;

    .select-all-button, .select-all-button{
      margin-right: 15px;
    }

  }
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.company-tab {
  padding: 33px 30px;
  margin: 10px 5px 0 0;
  border-radius: 4px;
  box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgba(96, 97, 99, 0.2),
  0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
  0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
}

.add-button {
  display: block;
  margin: 10px auto;
  padding: 10px;
  font-weight: 800;
  font-size: 14px;
  text-transform: none;
}

.edit-company-form {
  width: 35%;
}

.profile_card {
  border-radius: 0;
  padding: 16px 15px;
  color: #191919 !important;
  transition-duration: 0.5s;
  border-left: 6px solid #FFFFFF00;
  
  &:not(:last-child) {
    border-bottom: 1px solid #c8c8c8;
  }
  
  &:hover {
    background: rgba(238, 238, 238, 1);
  }
  
  &-active {
    //background: #c1ccd39e;
    transition-duration: 0.5s;
    border-left: 6px solid var(--bg-dark);
  }
}

.bounce-enter-active {
  animation: bounce-in 0.4s;
}

@keyframes bounce-in {
  0% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0px);
  }
}

.client-msg{
  @include font-headline-1;
  text-align: center;
  margin-top: 50px;
}
</style>
