<script>
import MainInput from "../../common/MainInput";
import MainForm from "../../common/MainForm";
import CompanyInfo from "../CompanyInfo";
import BankInfo from "../BankInfo";
import { validateInn, validateBik, validateRs } from "../../../utils/validators";
import { bus } from "../../../router/bus";

export default {
  name: "SelfRegistrationMessage",
  components: {
    MainForm,
    MainInput,
    CompanyInfo,
    BankInfo
  },
  data: () => ({
    key : "",
    error: {
      code: null,
      message: null
    },
    showloader: false,
    is_teo_checked: false,
    taxNumber: '',
    bankNumber: '',
    billNumber: '',
    // overallTarget: '',
    inn_checked: false,
    bik_checked: false,
    account_checked: false,
    companyInfo: {},
    bankInfo: {},
    profileData: {},
    showBlockDialog: false,
    isBlockMessage: '',
    
    isInn: false,
    companyIndex: 0,
  }),
  
  beforeDestroy() {
    if (!this.isAuth) {
      this.$_removesetting('token')
      this.$_removesetting('user_id')
      this.$_removesetting('login')
      this.$_removesetting('email')
      this.$_removesetting('is_auth')
      this.$_removesetting('client_id')
      this.$_removesetting('fio')
      this.$_removesetting('clnt_name')
      this.$_removesetting('manager_email')
      this.$_removesetting('is_show_bm_messages')
      this.$_removesetting('manager_name')
      this.$_removesetting('manager_phone')
      this.$_removesetting('is_block')
    }
  },
  
  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    }
  },
  
  mounted() {
    this.isInn = !!this.$route.query.inn;
    this.companyIndex = this.$route.query.key;
    console.log(this.companyIndex)
    if (this.isInn) {
      this.getUser()
    }
  },
  
  methods:{
    checkInn() {
      this.error.message = null
      validateInn(this.taxNumber, this.error)
      if (this.error.message == null) {
        this.registerbyinn()
      }
    },

    checkBik() {
      this.error.message = null
      validateBik(this.bankNumber, this.error)
      if (this.error.message == null) {
        this.registerbybik()
      }
    },

    checkAccount() {
      this.error.message = null
      validateRs(this.billNumber, this.bankNumber, this.error)
      if (this.error.message == null) {
        this.account_checked = true
      }
    },

    getUser() {
      this.showloader = true
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.profileData = JSON.parse(data.message)
          if (this.companyIndex != undefined)  {
            this.taxNumber = this.profileData.payers_data[this.companyIndex].inn
          } else {
            this.taxNumber = this.profileData.payers_data[0].inn
          }
          this.registerbyinn()
        }
      });
    },

    changeDocumentStatus () {
      this.$postapi(this.$address + this.$setters.setUserDocumentStatus.uri, {
        method: this.$setters.setUserDocumentStatus.name,
        token: this.$_getsetting('token'),
        client_id: this.$_getsetting('client_id'),
        // is_teo_contract: 1
        status_name: "is_teo_contract",
        value: 1
      }).then(data => {
        if (data.error != 0) {
          // bus.$emit("show_notify", {
          //   color: "error",
          //   notifytext: data.message
          //  })
        }
     });
    },

    createContragent() {
      if (this.is_teo_checked === true) {
        this.showloader = true
        this.changeDocumentStatus()
        this.$postapi(this.$address + this.$setters.setNewContragent.uri, {
          method: this.$setters.setNewContragent.name,
          token: this.$_getsetting('token'),
          bankInfo: this.bankInfo,
          companyInfo: this.companyInfo,
          inn: this.taxNumber,
          bik: this.bankNumber,
          account: this.billNumber,
          // overallTarget: this.overallTarget
        }).then(data => {
          if (data.error == 0) {
            this.setClientId()
            this.showBlockMessage()
            this.showloader = false
            // bus.$emit("show_notify", {
            //   color: "success",
            //   notifytext: data.message
            // });
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Пожалуйста, ознакомтесь с договором Транспортной Экспедиции"
        });
      }
    },
    
    closeContragentForm() {
      this.showBlockDialog = false
      this.$router.push("/")
    },
    
    showBlockMessage() {
      if (this.$_getsetting('is_block') == 1) {
        this.isBlockMessage = 'Для оформления заявки в it-портале Vedexx необходимо подписать договор. Договор отправлен Вам по ЭДО либо на электронную почту.'
      } else {
        this.isBlockMessage = 'Договор отправлен Вам на почту. Вам доступна возможность оформления заявок в it-портале Vedexx.'
      }
      this.showBlockDialog = true
    },
    
    setClientId() {
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.profileData = JSON.parse(data.message)
          this.$_setsetting('client_id', this.profileData.client_data[0].clnt_id)
          this.$_setsetting('is_auth', true)
        }
      });
    },

    registerbyinn() {
      this.showloader = true
      this.$getapi(this.$address + this.$getters.getCompanyByInn.uri + this.taxNumber)
        .then(data => {
          this.showloader = false
          if (data.companyname != null){
            this.inn_checked = true
            this.companyInfo = data
          }
        });
    },

    registerbybik() {
      this.showloader = true
      this.$getapi(this.$address + this.$getters.getBankByBik.uri + this.bankNumber)
        .then(data => {
          this.showloader = false
          if (data.bik != null){
            this.bik_checked = true
            this.bankInfo = data
          } else {
            this.error.message = "Неверный номер БИК"
          }
        });
    },
    goToProfilePage() {
      this.$router.push("/Profile")
    },
  }
}
</script>

<template>
  <div class="signup">
    <main-form class="signup-form" title="Заполнение данных">
      <button @click="goToProfilePage" class="signup-form__back">
        <img src="../../../assets/img/icons/Аrrow_right.svg" alt="arrow-left">
      </button>
      <v-alert
        v-if="!isAuth"
        text
        dense
        class="my-4"
        color="var(--main-bg-color)"
        icon="mdi-alert-circle"
        border="left"
        width="100%"
      >
        <strong>Для входа в систему заполните реквизиты вашей компании</strong>
      </v-alert>
      
      <div class="signup-form-checkbox">
        <v-checkbox
          color="var(--main-bg-color)"
          v-model="is_teo_checked"
          :rules="[v => !!v || 'Ознакомтесь для продолжения!']"
        />
        <div class="signup-form-checkbox-label">
          Я ознакомлен с
          <router-link :to="'document/11'" target="_blank">
            договором ТЭО
          </router-link>
        </div>
      </div>

      <div>
        <main-input
          class="signup-input"
          label="ИНН"
          v-model="taxNumber"
          type="number"
          @keyup="checkInn"
          :disabled="isInn"
          :error="inn_checked ? '' : error.message"
        />

        <v-btn
          text
          color="primary"
          outlined
          @click="checkInn"
          v-if="!inn_checked && !isInn"
          class="signup-btn"
        >
          Проверить
        </v-btn>

        <div class="signup-loader-block" v-if="showloader == true && !inn_checked">
          <v-progress-circular
            :size="100"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-if="inn_checked">
          <company-info class="info-banner" :info="companyInfo"/>
        </div>
      </div>

      <div v-if="inn_checked || isInn == true">
        <main-input
          class="signup-input"
          label="БИК"
          v-model="bankNumber"
          type="number"
          @keyup="checkBik"
          :error="bik_checked  ? '' : error.message"
        />

        <v-btn
          text
          outlined
          color="primary"
          @click="checkBik"
          v-if="!bik_checked"
          class="signup-btn"
        >
          Проверить
        </v-btn>

        <div class="signup-loader-block" v-if="showloader == true && !bik_checked">
          <v-progress-circular
            :size="100"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="bik_checked">
          <bank-info class="info-banner" :info="bankInfo"/>
        </div>
      </div>

      <main-input
        v-if="bik_checked"
        class="signup-input"
        label="Расчётный счёт"
        v-model="billNumber"
        type="number"
        @keyup="checkAccount"
        :error="inn_checked && !account_checked ? error.message : ''"
      />

      <v-btn
        text
        outlined
        color="primary"
        v-if="bik_checked && !account_checked"
        class="signup-btn"
        @click="checkAccount"
      >
        Проверить
      </v-btn>

      <br/>

      <v-btn
        text
        outlined
        color="primary"
        @click="createContragent"
        v-if="account_checked && showloader == false"
        class="signup-btn"
      >
        Отправить
      </v-btn>

    </main-form>
    
    <v-dialog
      v-model="showBlockDialog"
      width="40%"
      persistent
    >
      <v-card class="pa-6">
        <v-card-title>
          Внимание!
        </v-card-title>
        <v-card-text class="card-main-text">
          {{isBlockMessage}}
        </v-card-text>
        
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="var(--main-bg-color)"
            dark
            class="px-5 transform-none signup-btn"
            @click="closeContragentForm"
          >
            Хорошо
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.signup {
  display: flex;
}

.signup-form {
  margin: 30px auto;
  width: 60%;

  &__back{
    position: absolute;
    top: 35px;
    transform: rotate(180deg);
  }
}

.signup-form-checkbox {
  top: 2px;
  display: flex;
  flex-direction: row;
}

.signup-form-checkbox-label {
  margin-top: 20px;
  @include font-body-2;
}

.signup-input {
  @include font-body-2;
  &:not(:first-child) {
    margin: 20px 0;
  }
}

.signup-loader-block {
  margin: auto;
  width: 20%;
}

.card-main-text {
  font-size: 15px;
  color: black !important;
}

.info-banner {
  margin: 20px auto;
}

.transform-none {
  text-transform: none;
}

.signup-btn{
  @include font-body-3;
}
</style>
